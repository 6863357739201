exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-accessories-to-your-cart-js": () => import("./../../../src/pages/add-accessories-to-your-cart.js" /* webpackChunkName: "component---src-pages-add-accessories-to-your-cart-js" */),
  "component---src-pages-add-accessories-to-your-order-js": () => import("./../../../src/pages/add-accessories-to-your-order.js" /* webpackChunkName: "component---src-pages-add-accessories-to-your-order-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-checkout-step-2-js": () => import("./../../../src/pages/checkout-step-2.js" /* webpackChunkName: "component---src-pages-checkout-step-2-js" */),
  "component---src-pages-checkout-success-js": () => import("./../../../src/pages/checkout-success.js" /* webpackChunkName: "component---src-pages-checkout-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-review-us-js": () => import("./../../../src/pages/review-us.js" /* webpackChunkName: "component---src-pages-review-us-js" */),
  "component---src-pages-schedule-my-pickup-js": () => import("./../../../src/pages/schedule-my-pickup.js" /* webpackChunkName: "component---src-pages-schedule-my-pickup-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

