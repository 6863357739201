export const zipCodes = [
  {
    label: "Salt Lake County",
    zipCodes: [
      84006, 84020, 84044, 84047, 84065, 84070, 84084, 84088, 84090, 84091,
      84092, 84093, 84094, 84095, 84101, 84102, 84103, 84104, 84105, 84106,
      84107, 84108, 84109, 84110, 84111, 84112, 84113, 84114, 84115, 84116,
      84117, 84118, 84119, 84120, 84121, 84122, 84123, 84124, 84125, 84126,
      84127, 84128, 84130, 84131, 84133, 84138, 84144, 84145, 84147, 84151,
      84152, 84153, 84157, 84158, 84165, 84170, 84171, 84180,
    ],
  },
  {
    label: "Utah County",
    zipCodes: [
      84003, 84004, 84013, 84042, 84043, 84057, 84058, 84059, 84062, 84097,
      84601, 84602, 84603, 84604, 84605, 84606, 84626, 84633, 84651, 84653,
      84655, 84660, 84663, 84664,
    ],
  },
  {
    label: "Davis, Tooele, Heber, and Nephi",
    zipCodes: [
      84010, 84011, 84014, 84015, 84016, 84025, 84037, 84040, 84041, 84054,
      84056, 84075, 84087, 84089, 84648, 84074, 84032,
    ],
  },
];

export const allDeliverableZipCodes = zipCodes.reduce(
  (acc, curr) => acc.concat(curr.zipCodes),
  []
);
